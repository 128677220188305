/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Icon } from "@blueprintjs/core";
import { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import Webcam from "react-webcam";
import { setCaptureImage, setHeader } from "../../redux/global/global.actions";
import { useAppDispatch } from "../../redux/hooks";
import "./take-picture.style.scss";

const TakePicture = () => {
  const dispatch = useAppDispatch();
  const cameraRef = useRef<any>(null);
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader({ title: "Ambil Foto Selfie", hasBack: true }));
  }, []);

  const capture = useCallback(() => {
    const image = cameraRef.current.getScreenshot();
    dispatch(setCaptureImage(image));
    history.goBack();
  }, [cameraRef]);

  return (
    <div className="selfie-page">
      <Webcam
        className="camera-container"
        screenshotFormat="image/jpeg"
        ref={cameraRef}
      />
      <Button className="take-picture-btn" onClick={capture}>
        <Icon icon="camera" color="#fff" />
        {`Ambil foto`}
      </Button>
    </div>
  );
};

export default TakePicture;
