import { Outstation } from "../_interfaces/outstation.interfaces";
import request from "../_network/request";
const outsourcingRecordUrl = "outsourcing_record";
const outsourcingUrl = "outsourcing";

const recordOutsource = (data: Outstation, token: string) => {
  return request({
    url: outsourcingRecordUrl,
    method: "POST",
    data: data,
    headers: {
      "x-access-token": token,
    },
  });
};

const getOutsourceOptions = (token: string) => {
  return request({
    url: outsourcingUrl,
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

const getApprovalOutsourcing = (token: string) => {
  return request({
    url: `${outsourcingRecordUrl}/by_user`,
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

export const OutsourcingServices = {
  recordOutsource,
  getOutsourceOptions,
  getApprovalOutsourcing,
};
