/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { setHeader, setLocation } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./maps.style.scss";
import Geocode from "react-geocode";
import { useHistory } from "react-router";
import { iconBuildings } from "./IconBuilding";

const Maps = () => {
  Geocode.setApiKey("AIzaSyAMN-K2gpJ5YD73wkzyg3_YPIJFJ7ceQIk");
  Geocode.setLanguage("ID");
  const dispatch = useAppDispatch();
  const [loc, setLoc] = useState({
    long: "",
    lat: "",
  });
  const { location } = useAppSelector((state) => state.clockin.masterData);
  const [address, setAddress] = useState("");
  const [isNearby, setIsNearby] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(setHeader({ title: "Pilih lewat peta", hasBack: true }));
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setLoc({
          long: pos.coords.longitude.toString(),
          lat: pos.coords.latitude.toString(),
        });
      },
      (err) => console.log(err),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }, []);

  useEffect(() => {
    if (loc.lat !== "") {
      location.forEach((item: any) => {
        const distance = getDistance(
          parseFloat(loc.lat),
          parseFloat(loc.long),
          parseFloat(item.lat),
          parseFloat(item.long)
        );
        if (distance <= 1) setIsNearby(true);
      });
      Geocode.fromLatLng(loc.lat, loc.long).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [loc, location]);

  const getDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    var R = 6371;
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    // console.log("distance in KM", d);
    return d;
  };

  const deg2rad = (deg: any) => {
    return deg * (Math.PI / 180);
  };

  const chooseLocation = () => {
    dispatch(setLocation(loc.lat, loc.long, address, isNearby));
    history.goBack();
  };

  return (
    <div className="map-page">
      {loc.long !== "" && (
        <MapContainer
          center={[parseFloat(loc.lat), parseFloat(loc.long)]}
          zoom={14}
          scrollWheelZoom={false}
        >
          <MapComponent
            setLatLong={(lat: string, long: string) =>
              setLoc({ lat: lat, long: long })
            }
          />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {location.map((item: any, i: number) => (
            <Marker
              key={i}
              position={[item.lat, item.long]}
              icon={iconBuildings}
            ></Marker>
          ))}
          <Marker position={[parseFloat(loc.lat), parseFloat(loc.long)]}>
            {/* <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup> */}
          </Marker>
        </MapContainer>
      )}
      <div className="form-container">
        <textarea
          id="location"
          value={address}
          placeholder="&#61442; Lokasi absen"
          disabled
        />
        <Button onClick={() => chooseLocation()} className="btn">
          Pilih lokasi
        </Button>
      </div>
    </div>
  );
};

const MapComponent = ({ setLatLong }: { setLatLong: Function }) => {
  const map = useMapEvents({
    // dragend: (e) => {
    //   const latlng = e.target.getCenter();
    //   setLatLong(latlng.lat, latlng.lng);
    // },
    // click: (e) => {
    //   const latlng = e.latlng;
    //   setLatLong(latlng.lat, latlng.lng);
    // },
  });
  return null;
};

export default Maps;
