import { Spinner } from "@blueprintjs/core";
import { FunctionComponent, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import Alert from "../components/alert";
import SuccessPopup from "../components/success-popup";
import LoginPage from "../pages/login/Login";
import { useAppSelector } from "../redux/hooks";
import UserRoutes from "./UserRoutes";

const MainRoutes = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const { loading, showSuccess } = useAppSelector((state) => state.globalState);
  const { userData } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setAuthenticated(userData.token ? true : false);
  }, [userData]);

  return (
    <div>
      <Alert />
      {showSuccess && <SuccessPopup />}
      {loading && (
        <div className="loading">
          <Spinner />
        </div>
      )}
      <Switch>
        {!authenticated ? (
          <Route exact key={1} path="/login" component={LoginPage} />
        ) : (
          <GuardedRoute
            path="/attendance"
            component={UserRoutes}
            auth={authenticated}
          />
        )}
        <Redirect
          exact
          from="**"
          to={authenticated ? "/attendance" : "/login"}
        />
      </Switch>
    </div>
  );
};

const GuardedRoute: FunctionComponent<{
  component: FunctionComponent;
  auth: boolean;
  path: string;
}> = ({ component, auth, path }) => (
  <Route
    path={path}
    component={component}
    // render={(props) =>
    //   auth ? (
    //     <Component {...props} />
    //   ) : (
    //     <Redirect exact from="**" to={"/login"} />
    //   )
    // }
  />
);

export default MainRoutes;
