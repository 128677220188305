import { LeaveRecord } from "../_interfaces";
import request from "../_network/request";
const leaveUrl: string = "leave";
const leaveRecordUrl: string = "leave_record";
const approvalUrl: string = "approval";

const getLeaveOptions = (token: string) => {
  return request({
    url: `${leaveUrl}`,
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

const getApprovalLeave = (token: string) => {
  return request({
    url: `${leaveRecordUrl}/by_user`,
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

const submitLeave = ({
  payload,
  token,
}: {
  payload: LeaveRecord;
  token: string;
}) => {
  let data = new FormData();
  let dateFrom = new Date(payload.date_from);

  data.append("approval_id", payload.approval_id);
  data.append("user_id", payload.user_id);
  data.append("leave_id", payload.leave_id);
  data.append("desc", payload.desc);
  data.append("date_from", dateFrom.toLocaleString());
  data.append("date_to", payload.date_to);
  if (payload.file) data.append("file", payload.file);
  // data.append("type", "izin_sakit");
  return request({
    url: `${leaveRecordUrl}`,
    method: "POST",
    data: data,
    headers: {
      "x-access-token": token,
    },
  });
};

const createApproval = (token: string) => {
  return request({
    url: approvalUrl,
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

export const LeaveServices = {
  getLeaveOptions,
  submitLeave,
  createApproval,
  getApprovalLeave,
};
