import { batch } from "react-redux";
import { Outstation } from "../../_interfaces/outstation.interfaces";
import { OutsourcingServices } from "../../_services/outsourcing.services";
import GlobalActionTypes from "../global/global.types";
import OutstationActionTypes from "./outstation.types";

export const getOutsourceOption = () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const token = state.auth.userData.token;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: OutstationActionTypes.GET_OUTSTATION_OPTIONS });
      });

      const options = await OutsourcingServices.getOutsourceOptions(token);

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: OutstationActionTypes.GET_OUTSTATION_OPTIONS_SUCCESS,
          payload: options.data.rows,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: OutstationActionTypes.GET_OUTSTATION_OPTIONS_FAILURE,
        });
      });
    }
  };
};

export const createOutsourceRecord = ({ payload }: { payload: Outstation }) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    const { approval } = state.clockin.masterData;
    payload.approval_id = approval.filter((item: any) => item.type === "Pending")[0]
      .id;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: OutstationActionTypes.SUBMIT_OUTSTATION });
      });

      const record = await OutsourcingServices.recordOutsource(
        payload,
        userData.token
      );

      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: OutstationActionTypes.SUBMIT_OUTSTATION_SUCCESS,
          payload: record,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_SUCCESS,
          payload: {
            title: "Pengajuan tugas luar kota berhasil",
            desc: "Pengajuan izin telah di ajukan harap tunggu approval dari HRD",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: OutstationActionTypes.SUBMIT_OUTSTATION_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: "error",
          },
        });
      });
    }
  };
};
