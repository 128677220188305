import ClockinActionTypes from "./clockin.types";

interface MasterData {
  status: Array<any>;
  location: Array<any>;
  approval: Array<any>;
}
interface ClockinState {
  attendanceStats: string;
  masterData: MasterData;
}

const INITIAL_STATE: ClockinState = {
  attendanceStats: "",
  masterData: {
    status: [],
    location: [],
    approval: [],
  }
};

const ClockinReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ClockinActionTypes.SEND_ATTENDANCE:
     return {
       ...state,
       attendanceStats: "sending",
     }
    case ClockinActionTypes.SEND_ATTENDANCE_SUCCESS:
     return {
       ...state,
       attendanceStats: "success",
     }
    case ClockinActionTypes.SEND_ATTENDANCE_FAILURE:
     return {
       ...state,
       attendanceStats: "failure",
     }
    case ClockinActionTypes.GET_MASTER_DATA:
      return {
        ...state,
        masterData: {
          status: [],
          location: [],
          approval: [],
        }
      }
    case ClockinActionTypes.GET_MASTER_DATA_SUCCESS:
      return {
        ...state,
        masterData: {
          status: action.payload.status,
          location: action.payload.location,
          approval: action.payload.approval,
        }
      }
    case ClockinActionTypes.GET_MASTER_DATA_FAILURE:
      return {
        ...state,
        masterData: {
          status: [],
          location: [],
          approval: [],
        }
      }
    default:
      return state;
  }
};

export default ClockinReducer;
