/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ButtonImage from "../../components/button-selfie";
import MapSection from "../../components/maps-section";
import { sendClockOutAttendance } from "../../redux/clock-in/clockin.actions";
import { setHeader, showAlert } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./clockout.style.scss";

const ClockOut = () => {
  const dispatch = useAppDispatch();
  const { image, lat, long, isNearby } = useAppSelector(
    (state) => state.globalState
  );
  const { status } = useAppSelector((state) => state.clockin.masterData)
  const [workDuration, setWorkDuration] = useState("");

  useEffect(() => {
    dispatch(setHeader({ title: "Absen Keluar", hasBack: true }));
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (image && image !== "") {
      if (isNearby) {
        dispatch(
          sendClockOutAttendance(
            {
              attendance_id: "",
              lat,
              long,
              type: "clock_out",
              time: "",
              file: image,
              desc: "",
            },
            workDuration
          )
        );
      } else {
        dispatch(
          showAlert(
            "Anda tidak sedang berada dalam lingkungan Kantor!",
            "error"
          )
        );
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} className="clock-out">
      <MapSection />
      <ButtonImage />
      <div className="work-duration">
        <div className="title">Pilih durasi kerja</div>
        {status.map((item: any, i: number) =>
          <div key={i}>
            <input
              type="radio"
              name="workDuration"
              checked={workDuration === item.id}
              onChange={(e: any) => setWorkDuration(e.target.value)}
              className="radio"
              id={item.id}
              value={item.id}
            />
            <label htmlFor={item.id} className="card-work-duration">
              <div className="title">
                <div>{item.status}</div>
                <div>{item.duration} Hours</div>
              </div>
              <div className="duration">
                <div>Time</div>
                <div>{item.desc}</div>
              </div>
            </label>
          </div>
        )}
        {/* <div>
          <input
            type="radio"
            name="workDuration"
            checked={workDuration === "Half day"}
            onChange={(e: any) => setWorkDuration(e.target.value)}
            className="radio"
            id="Halfday"
            value="Half day"
          />
          <label htmlFor="Halfday" className="card-work-duration">
            <div className="title">
              <div>Halfday</div>
              <div>4 Hours</div>
            </div>
            <div className="duration">
              <div>Time</div>
              <div>08:00 - 12.00 / 13:00 - 16.00</div>
            </div>
          </label>
        </div>
        <div>
          <input
            type="radio"
            name="workDuration"
            checked={workDuration === "Overtime"}
            onChange={(e: any) => setWorkDuration(e.target.value)}
            className="radio"
            id="Overtime"
            value="Overtime"
          />
          <label htmlFor="Overtime" className="card-work-duration">
            <div className="title">
              <div>Overtime</div>
              <div>2 Hours</div>
            </div>
            <div className="duration">
              <div>Time</div>
              <div>18:00 (+2 Hours)</div>
            </div>
          </label>
        </div> */}
      </div>
      <button type="submit" className="submit-btn">
        Absen Keluar
      </button>
    </form>
  );
};

export default ClockOut;
