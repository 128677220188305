import { AttendanceRecord } from "../_interfaces";
import request from "../_network/request";
const attendanceRecordUrl: string = "attendance_record";
const attendanceUrl: string = "attendance";

const record = (payload: AttendanceRecord, token: string) => {
  let data = new FormData();
  data.append("attendance_id", payload.attendance_id);
  data.append("time", payload.time);
  data.append("type", payload.type);
  data.append("lat", payload.lat);
  data.append("long", payload.long);
  if(payload.desc !== "") {
    data.append("desc", payload.desc);
  }
  let blobBin = atob(payload.file.split(",")[1]);
  let array = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  let file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  data.append("file", file, `${payload.attendance_id}-${payload.type}.jpeg`);
  return request({
    url: `${attendanceRecordUrl}`,
    method: "POST",
    data: data,
    headers: {
      "x-access-token": token,
    },
  });
};

const checkAttendance = (token: string) => {
  return request({
    url: `${attendanceUrl}/check`,
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

const createAttendance = (
  status_id: string,
  location_id: string,
  approval_id: string,
  token: string
) => {
  return request({
    url: attendanceUrl,
    method: "POST",
    data: {
      status_id,
      location_id,
      approval_id,
    },
    headers: {
      "x-access-token": token,
    },
  });
};

const updateAttendance = (attendance_id: string, status_id: string, token: string) => {
  return request({
    url: `${attendanceUrl}/${attendance_id}`,
    method: "PUT",
    data: {
      status_id,
    },
    headers: {
      "x-access-token": token,
    },
  });
};

const getLocation = (token: string) => {
  return request({
    url: "location",
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

const getStatus = (token: string) => {
  return request({
    url: "status",
    method: "GET",
    headers: {
      "x-access-token": token,
    },
  });
};

export const AttendanceServices = {
  record,
  checkAttendance,
  createAttendance,
  getLocation,
  getStatus,
  updateAttendance,
};
