/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import {
  getLeaveApproval,
  getOutsourceApproval,
} from "../../redux/approval/approval.actions";
import { setHeader } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./approval.style.scss";

const Approval = () => {
  const dispatch = useAppDispatch();
  const { leaveList, outsourceList } = useAppSelector(
    (state) => state.approval
  );
  const { approval } = useAppSelector((state) => state.clockin.masterData);
  const [tab, setTab] = useState(0);
  const [listView, setListView] = useState([]);

  useEffect(() => {
    dispatch(setHeader({ title: "Approval Request", hasBack: true }));
  }, []);

  useEffect(() => {
    if (tab === 0) {
      dispatch(getLeaveApproval());
    } else {
      dispatch(getOutsourceApproval());
    }
  }, [tab]);

  useEffect(() => {
    if (tab === 0) setListView(leaveList);
    else setListView(outsourceList);
  }, [leaveList, outsourceList]);

  const generateApprovalString = (id: string) =>
    approval.filter((item: any) => item.id === id)[0].type;

  const generateIcon = (id: string) => {
    const approvalText = approval.filter((item: any) => item.id === id)[0].type;
    if (approvalText === "Approved") return "tick-circle";
    if (approvalText === "Rejected") return "delete";
    return "time";
  };

  const generateDate = (item: any) => {
    if (item.date_from) {
      const from = item.date_from.split("T")[0];
      const to = item.date_to.split("T")[0];
      return `From ${from} To ${to}`;
    }
    return item.createdAt.split("T")[0];
  };

  return (
    <div className="approval">
      <div className="tabs">
        <button
          onClick={() => setTab(0)}
          className={`${tab === 0 && "active"}`}
        >
          Izin
        </button>
        <button
          onClick={() => setTab(1)}
          className={`${tab === 1 && "active"}`}
        >
          Tugas Luar
        </button>
      </div>
      <div className="list-view">
        {listView.map((item: any, i: number) => (
          <div
            className={`card-leave ${generateApprovalString(item.approval_id)}`}
            key={i}
          >
            <div>
              <h3>{item?.Leave?.leave || item?.Outsourcing?.type}</h3>
              <p>{generateDate(item)}</p>
            </div>
            <div
              className={`icon-wrapper ${generateApprovalString(
                item.approval_id
              )}`}
            >
              <Icon icon={generateIcon(item.approval_id)} size={20} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Approval;
