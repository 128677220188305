// import { useSelector } from "react-redux";

import { useState } from "react";
import { login } from "../../redux/auth/auth.actions";
import { useAppDispatch } from "../../redux/hooks";
import { Login } from "../../_interfaces";
import { Button, Icon } from "@blueprintjs/core";
import Logo from "../../assets/logo.svg";
import "./login.style.scss";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const initForm = {
    email: "",
    password: "",
  };
  const [form, setForm] = useState<Login>(initForm);

  const handleChange = (e: any) => {
    setForm((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  return (
    <div className="login">
      <div className="login-container">
        <div className="logo-container">
          <img src={Logo} alt="gajiku-logo" />
          <div className="title">Gajiku</div>
        </div>
        <form
          className="form-container"
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(login(form));
          }}
        >
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              inputMode="email"
              required
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Password</label>
            <input type="password" name="password" required onChange={handleChange} />
          </div>
          <Button type="submit" className="btn">
            Login
          </Button>
        </form>
        <div className="another-container">
          <div className="forgot-password">Lupa Password?</div>
          <div className="register">
            Daftar <Icon icon={"arrow-right"} iconSize={10} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
