/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import Approval from "../pages/approval/Approval";
import TakePicture from "../pages/camera/TakePicture";
import ClockIn from "../pages/clockIn/ClockIn";
import ClockOut from "../pages/clockOut/ClockOut";
import Home from "../pages/home/Home";
import Maps from "../pages/maps/Maps";
import OutstationPage from "../pages/outstation/Outstation";
import PermitPage from "../pages/permit/Permit";
import { logout } from "../redux/auth/auth.actions";
import { getMasterData } from "../redux/clock-in/clockin.actions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import "./header.style.scss";

const UserRoutes = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { title, hasBack } = useAppSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(getMasterData());
  }, []);

  return (
    <div>
      <div className="header">
        {hasBack && (
          <div className="back-button" onClick={() => history.goBack()}>
            <Icon icon="arrow-left" />
          </div>
        )}
        <div className="title">{title}</div>
        <div className="logout-button" onClick={() => dispatch(logout())}>
          <Icon icon="log-out" />
        </div>
      </div>
      <div className="white-space" />
      <Switch>
        <Route exact component={Home} path={`${path}/`} />
        <Route exact component={ClockIn} path={`${path}/clockIn`} />
        <Route exact component={ClockOut} path={`${path}/clockOut`} />
        <Route exact component={PermitPage} path={`${path}/permit`} />
        <Route exact component={OutstationPage} path={`${path}/outstation`} />
        <Route exact component={Maps} path={`/attendance/maps`} />
        <Route exact component={TakePicture} path="/attendance/selfie" />
        <Route exact component={Approval} path="/attendance/approval" />
        <Redirect exact from="**" to={`/attendance/`} />
      </Switch>
    </div>
  );
};

export default UserRoutes;
