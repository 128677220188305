/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { setHeader } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  createOutsourceRecord,
  getOutsourceOption,
} from "../../redux/outstation/outstation.actions";
import { Outstation } from "../../_interfaces/outstation.interfaces";
import "./outstation.style.scss";

const OutstationPage = () => {
  const dispatch = useAppDispatch();
  const { outstationOptions } = useAppSelector((state) => state.outsource);
  const defaultForm: Outstation = {
    outsourcing_id: "",
    city: "",
    destination_city: "",
    desc: "",
    approval_id: "",
  };
  const [form, setForm] = useState<Outstation>(defaultForm);

  useEffect(() => {
    dispatch(setHeader({ title: "Tugas Luar Kota", hasBack: true }));
    dispatch(getOutsourceOption());
  }, []);

  const handleChange = (e: any) => {
    setForm((temp: Outstation) => {
      return { ...temp, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(createOutsourceRecord({ payload: form }));
  };

  return (
    <form onSubmit={handleSubmit} className="outstation">
      <input
        type="text"
        placeholder="Kota Asal"
        name="city"
        value={form?.city}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        placeholder="Kota Tujuan"
        name="destination_city"
        value={form?.destination_city}
        onChange={handleChange}
        required
      />
      <select
        className="select"
        placeholder="Jenis Pekerjaan"
        name="outsourcing_id"
        value={form?.outsourcing_id}
        onChange={handleChange}
        required
      >
        <option value="" disabled hidden>
          Jenis Pekerjaan
        </option>
        {outstationOptions.map((item: any) => (
          <option value={item.id} key={item.id}>
            {item.type}
          </option>
        ))}
      </select>
      <textarea
        placeholder="Keterangan"
        name="desc"
        value={form?.desc}
        onChange={handleChange}
      />
      <button type="submit" className="submit-btn">
        Submit
      </button>
    </form>
  );
};

export default OutstationPage;
