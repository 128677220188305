import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ApprovalReducer from './approval/approval.reducer';
import AuthReducer from './auth/auth.reducer';
import ClockinReducer from './clock-in/clockin.reducer';
import GlobalReducer from './global/global.reducer';
import OutstationReducer from './outstation/outstation.reducer';
import PermitReducer from './permit/permit.reducer';

const persistConfig = {
  key: 'gajiku-attendance',
  storage,
};

const rootReducer = combineReducers({
  auth: AuthReducer,
  globalState: GlobalReducer,
  clockin: ClockinReducer,
  permit: PermitReducer,
  outsource: OutstationReducer,
  approval: ApprovalReducer
});

export default persistReducer(persistConfig, rootReducer);
