import { batch } from "react-redux";
import { AttendanceRecord } from "../../_interfaces";
import { AttendanceServices } from "../../_services/attendance.services";
import { LeaveServices } from "../../_services/leave.services";
import GlobalActionTypes from "../global/global.types";
import ClockinActionTypes from "./clockin.types";

export const sendAttendance = (data: AttendanceRecord) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    const { approval, location, status } = state.clockin.masterData;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ClockinActionTypes.SEND_ATTENDANCE });
      });
      data.time = new Date().toLocaleString();
      const pending = approval.filter((item: any) => item.type === "Pending")[0]
        .id;

      const check = await AttendanceServices.checkAttendance(userData.token);
      if (!check.data.is_active) {
        const createAttendance = await AttendanceServices.createAttendance(
          status[0].id,
          location[1].id,
          pending,
          userData.token
        );
        data.attendance_id = createAttendance.data.id;
        const clockin = await AttendanceServices.record(data, userData.token);

        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ClockinActionTypes.SEND_ATTENDANCE_SUCCESS,
            payload: clockin,
          });
          dispatch({
            type: GlobalActionTypes.SHOW_SUCCESS,
            payload: {
              title: "Absen masuk berhasil",
              desc: "Harap tunggu approval dari HRD",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
            payload: "Anda sudah melakukan absen masuk",
          });
          dispatch({
            type: GlobalActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Anda sudah melakukan absen masuk",
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const sendClockOutAttendance = (
  data: AttendanceRecord,
  workDuration: string
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    try {
      batch(() => {
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
        dispatch({ type: ClockinActionTypes.SEND_ATTENDANCE });
      });
      data.time = new Date().toLocaleString();

      const check = await AttendanceServices.checkAttendance(userData.token);
      if (!check.data.is_active) {
        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
            payload: "Anda sudah melakukan absen keluar",
          });
          dispatch({
            type: GlobalActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Anda belum melakukan absen masuk atau sudah melakukan absen keluar",
              alertType: "error",
            },
          });
        });
      } else {
        data.attendance_id = check.data.attendance_id;
        const clockin = await AttendanceServices.record(data, userData.token);
        const updateAttendance = await AttendanceServices.updateAttendance(
          clockin.data.attendance_id,
          workDuration,
          userData.token
        );

        batch(() => {
          dispatch({ type: GlobalActionTypes.HIDE_LOADING });
          dispatch({
            type: ClockinActionTypes.SEND_ATTENDANCE_SUCCESS,
            payload: { clockin, updateAttendance },
          });
          dispatch({
            type: GlobalActionTypes.SHOW_SUCCESS,
            payload: {
              title: "Absen keluar berhasil",
              desc: "",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
        dispatch({
          type: ClockinActionTypes.SEND_ATTENDANCE_FAILURE,
          payload: error,
        });
        dispatch({
          type: GlobalActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Terjadi kesalahan saat mengirim data",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const getMasterData = () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    try {
      batch(() => {
        dispatch({ type: ClockinActionTypes.GET_MASTER_DATA });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const status = await AttendanceServices.getStatus(userData.token);
      const location = await AttendanceServices.getLocation(userData.token);
      const approval = await LeaveServices.createApproval(userData.token);

      batch(() => {
        dispatch({
          type: ClockinActionTypes.GET_MASTER_DATA_SUCCESS,
          payload: {
            status: status.data.rows,
            location: location.data.rows,
            approval: approval.data.rows,
          },
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ClockinActionTypes.GET_MASTER_DATA_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};
