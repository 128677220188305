import { Icon } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { hideSuccess } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const SuccessPopup = () => {
  const dispatch = useAppDispatch();
  const { successTitle, successDesc } = useAppSelector(
    (state) => state.globalState
  );
  const history = useHistory();

  const handleClick = () => {
    dispatch(hideSuccess());
    history.push("/attendance");
  };

  return (
    <div className="popup">
      <div className="alert-container">
        <Icon icon="tick-circle" className="icon" size={60} />
        <p className="title">{successTitle}</p>
        {successDesc !== "" && <p className="subtitle">{successDesc}</p>}
        <button onClick={handleClick} className="btn">
          Kembali ke home
        </button>
      </div>
    </div>
  );
};

export default SuccessPopup;
