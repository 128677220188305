import { Login, Register } from "../_interfaces";
import request from "../_network/request";
const authUrl: string = "user/auth";

const login = (payload: Login) => {
  return request({
    url: `${authUrl}/signin`,
    method: "POST",
    data: payload,
  });
};

const register = (payload: Register) => {
  return request({
    url: `${authUrl}/signup`,
    method: "POST",
    data: payload,
  });
};

export const AuthServices = {
  login,
  register,
};
