/* eslint-disable react-hooks/exhaustive-deps */
// import { Button, Icon } from "@blueprintjs/core";
import { useEffect, useState } from "react";
// import { useHistory, useRouteMatch } from "react-router";
import ButtonImage from "../../components/button-selfie";
import MapSection from "../../components/maps-section";
import { sendAttendance } from "../../redux/clock-in/clockin.actions";
import { setHeader, showAlert } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./clockin.style.scss";
const ClockIn = () => {
  const dispatch = useAppDispatch();
  const { image, lat, long, isNearby } = useAppSelector(
    (state) => state.globalState
  );
  const [desc, setDesc] = useState("");
  // const { path } = useRouteMatch();
  // const history = useHistory();

  useEffect(() => {
    dispatch(setHeader({ title: "Absen Masuk", hasBack: true }));
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (image && image !== "") {
      if (isNearby) {
        dispatch(
          sendAttendance({
            attendance_id: "",
            lat,
            long,
            type: "clock_in",
            time: "123",
            file: image,
            desc: desc,
          })
        );
      } else {
        dispatch(
          showAlert(
            "Anda tidak sedang berada dalam lingkungan Kantor!",
            "error"
          )
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="clock-in">
      <MapSection />
      <ButtonImage />
      <div className="container">
        <textarea
          className="reason"
          placeholder="Keterangan"
          onChange={(e: any) => setDesc(e.target.value)}
        />
      </div>
      <button type="submit" className="submit-btn">
        Absen Masuk
      </button>
    </form>
  );
};

export default ClockIn;
