import AuthActionTypes from "./auth.types";
import {} from "../../";
import { AuthServices } from "../../_services";
import { Login } from "../../_interfaces";
import { batch } from "react-redux";
import GlobalActionTypes from "../global/global.types";

export const login = (payload: Login) => {
  return async (dispatch: any) => {
    try {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING});
      })
      
      const checkin = await AuthServices.login(payload);
      
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: checkin.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING});
      })
    } catch (error) {
      batch(() => {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: error });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING});
      })
    }
  };
};

export const logout = () => {
  return async (dispatch: any) => {
    dispatch({ type: AuthActionTypes.LOGOUT });
  };
};
