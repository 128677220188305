import { batch } from "react-redux";
import { LeaveServices } from "../../_services/leave.services";
import { OutsourcingServices } from "../../_services/outsourcing.services";
import GlobalActionTypes from "../global/global.types";
import ApprovalActionTypes from "./approval.types";

export const getLeaveApproval = () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    try {
      batch(() => {
        dispatch({ type: ApprovalActionTypes.GET_LEAVE_APPROVAL });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const leaveList = await LeaveServices.getApprovalLeave(userData.token);

      batch(() => {
        dispatch({
          type: ApprovalActionTypes.GET_LEAVE_APPROVAL_SUCCESS,
          payload: leaveList.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ApprovalActionTypes.GET_LEAVE_APPROVAL_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const getOutsourceApproval = () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { userData } = state.auth;
    try {
      batch(() => {
        dispatch({ type: ApprovalActionTypes.GET_OUTSOURCE_APPROVAL });
        dispatch({ type: GlobalActionTypes.SHOW_LOADING });
      });

      const outsourceList = await OutsourcingServices.getApprovalOutsourcing(userData.token);

      batch(() => {
        dispatch({
          type: ApprovalActionTypes.GET_OUTSOURCE_APPROVAL_SUCCESS,
          payload: outsourceList.data,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ApprovalActionTypes.GET_OUTSOURCE_APPROVAL_FAILURE,
          payload: error,
        });
        dispatch({ type: GlobalActionTypes.HIDE_LOADING });
      });
    }
  };
};