/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import Datepicker from "../../components/date-picker";
import { setHeader } from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  createLeaveRecord,
  getOptions,
} from "../../redux/permit/permit.actions";
import { LeaveRecord } from "../../_interfaces";
import "./permit.style.scss";

const PermitPage = () => {
  const dispatch = useAppDispatch();
  const { leaveOptions } = useAppSelector((state) => state.permit);
  const [form, setForm] = useState<LeaveRecord>({
    approval_id: "",
    user_id: "",
    leave_id: "",
    desc: "",
    date_from: "",
    date_to: "",
    file: null,
  });

  useEffect(() => {
    dispatch(setHeader({ title: "Pengajuan Izin", hasBack: true }));
    dispatch(getOptions());
  }, []);

  const handleChange = (e: any) => {
    setForm((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    dispatch(createLeaveRecord({ payload: form }));
  };

  return (
    <div className="permit">
      <select className="select" name="leave_id" onChange={handleChange}>
        <option hidden value="">
          Jenis Izin
        </option>
        {leaveOptions.map((opt: any, i: number) => (
          <option key={i} value={opt.id}>
            {opt.leave}
          </option>
        ))}
      </select>
      <Datepicker
        className="select"
        name="date_from"
        value={form.date_from}
        placeholder="Tanggal Mulai"
        onChange={handleChange}
      />
      <Datepicker
        className="select"
        name="date_to"
        value={form.date_to}
        placeholder="Tanggal Selesai"
        onChange={handleChange}
      />
      <div className="upload-photo">
        <input
          type="file"
          id="file-izin"
          accept="image/*"
          onChange={(e: any) =>
            setForm((temp: LeaveRecord) => ({
              ...temp,
              file: e.target.files[0],
            }))
          }
        />
        <div className="label">Unggah Foto (.jpeg, .jpg, .png)</div>
        <label htmlFor="file-izin" className="file-box">
          <p className="text">{form.file ? form.file.name : "Pilih File"}</p>
          <Button className="btn">Browse</Button>
        </label>
      </div>
      <textarea placeholder="Keterangan" name="desc" onChange={handleChange} />
      <div onClick={() => handleSubmit()} className="submit-btn">
        Submit
      </div>
    </div>
  );
};

export default PermitPage;
