import AuthActionTypes from "./auth.types";

interface AuthState {
  userData: object;
}

const INITIAL_STATE: AuthState = {
  userData: {},
};

const AuthReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        ...state,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        userData: {},
      };
    default:
      return state;
  }
};

export default AuthReducer;
