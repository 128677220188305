import { Icon } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { useAppSelector } from "../../redux/hooks";
import "./map-section.style.scss";

const MapSection = () => {
  const history = useHistory();
  const { address } = useAppSelector(state => state.globalState);

  return (
    <div className="form-map">
      <label htmlFor="location">Lokasi anda saat ini</label>
      <textarea
        id="location"
        placeholder="&#61442; Tuliskan lokasi absen anda"
        value={address}
        required
        disabled
      />
      <div
        className="btn-icon"
        onClick={() => {
          history.push(`/attendance/maps`);
        }}
      >
        <Icon icon="map-marker" size={12} />
        {` Pilih lewat peta`}
      </div>
    </div>
  );
};

export default MapSection;
