/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import {
  setHeader,
  unsetCaptureImage,
  unsetLocation,
} from "../../redux/global/global.actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import "./home.style.scss";

const Home = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { full_name } = useAppSelector(state => state.auth.userData)
  const dispatch = useAppDispatch();
  const [greetings, setGreetings] = useState("");

  useEffect(() => {
    dispatch(setHeader({ title: "Home" }));
    dispatch(unsetCaptureImage());
    dispatch(unsetLocation());
    let today = new Date();
    if (today.getHours() < 12) {
      setGreetings("Good Morning");
    } else if (today.getHours() < 18) {
      setGreetings("Good Afternoon");
    } else {
      setGreetings("Good Evening");
    }
  }, []);

  return (
    <div className="home">
      <div className="profile-container">
        <img src="https://picsum.photos/200" alt="profile-user" />
        <div className="greeting">{greetings}</div>
        <div className="name">{full_name}</div>
      </div>
      <div className="button-container">
        <div className="btn" onClick={() => history.push(`${path}clockIn`)}>
          <div className="text-section">
            <div className="title">Absen Masuk</div>
            <div className="desc">Lorem ipsum dolor sit amet</div>
          </div>
          <Icon color="#fff" icon="document-open" size={30} />
        </div>
        <div className="btn" onClick={() => history.push(`${path}clockOut`)}>
          <div className="text-section">
            <div className="title">Absen Keluar</div>
            <div className="desc">Lorem ipsum dolor sit amet</div>
          </div>
          <Icon color="#fff" icon="home" size={30} />
        </div>
        <div className="btn" onClick={() => history.push(`${path}permit`)}>
          <div className="text-section">
            <div className="title">Pengajuan Izin</div>
            <div className="desc">Lorem ipsum dolor sit amet</div>
          </div>
          <Icon color="#fff" icon="list-detail-view" size={30} />
        </div>
        <div className="btn" onClick={() => history.push(`${path}outstation`)}>
          <div className="text-section">
            <div className="title">Tugas Luar Kota</div>
            <div className="desc">Lorem ipsum dolor sit amet</div>
          </div>
          <Icon color="#fff" icon="airplane" size={30} />
        </div>
        <div className="btn" onClick={() => history.push(`${path}approval`)}>
          <div className="text-section">
            <div className="title">Approval Request</div>
            <div className="desc">Lorem ipsum dolor sit amet</div>
          </div>
          <Icon color="#fff" icon="form" size={30} />
        </div>
      </div>
    </div>
  );
};

export default Home;
