import { useRef } from "react";

interface DatePickerProps {
  name: string;
  value: string;
  placeholder: string;
  className: string;
  onChange: any;
}

const Datepicker = ({
  name,
  value,
  placeholder,
  className,
  onChange,
}: DatePickerProps) => {
  const inputRef = useRef<any>(null);

  const handleFocus = (e: any) => {
    inputRef.current.type = "date";
  };

  const handleBlur = (e: any) => {
    inputRef.current.type = "text";
  };

  const handleChange = (e: any) => {
    inputRef.current.type = "text";
    onChange(e);
  }

  return (
    <input
      id={name}
      name={name}
      type="text"
      className={className}
      ref={inputRef}
      placeholder={placeholder}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

export default Datepicker;
