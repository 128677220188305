import { Button, Icon } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { useAppSelector } from "../../redux/hooks";
import "./button-selfie.style.scss";

const ButtonImage = () => {
  const { image, lat, long } = useAppSelector((state) => state.globalState);
  const history = useHistory();

  return (
    <>
      {(image && image !== "") && (
        <img src={image} className="image-capture" alt="user-face" />
      )}
      <div className="container">
        <Button
          className="btn-selfie"
          onClick={() => {
            history.push("/attendance/selfie");
          }}
          disabled={(lat === "") || long === ""}
        >
          <Icon icon="camera" color="#fff" />
          {`Ambil foto selfie`}
        </Button>
      </div>
    </>
  );
};

export default ButtonImage;
